import React, { Suspense, useState, useRef, useMemo } from 'react';
import {
    Box,
    Grid,
    H2,
    H3,
    P,
    Btn,
} from '@saladbob/sassafras'

import Link from 'next/link';
import dynamic from 'next/dynamic'
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import TournamentLabel from '../../labels/TournamentLabel';
import { keys as stateKeys } from '../../../data/provinces';
import Tabs from '../../navigation/Tabs';
import ToolTip from '../../ToolTip';

const Map = dynamic(() => import('../../media/Map'), {
    ssr: false,
});

import { Tournament } from '../../../types';

type Props = {
    tournaments?: Tournament[],
}

dayjs.extend(advancedFormat);

const TournamentCard: React.FC<{tournament: Tournament}> = ({ tournament }) => {
    return (
        <Box>
            <Box
                padding="md"
            >
                <Grid columns={{ phone: '120px 1fr', laptop: 'max-content 1fr' }} columnGap="md" pt="sm" gridGap="md">
                    <div className="hide-on-laptop">
                        {tournament.image && (
                            <img
                                alt={tournament.title}
                                src={tournament.image.sizes.thumbnail.url}
                                style={{
                                    width: '100%',
                                    objectFit: 'cover',
                                    aspectRatio: '1/1',
                                    borderRadius: '100%',
                                }}
                            />
                        )}
                    </div>
                    <div>
                        <H3
                            color="white"
                            mb="0"
                        >
                            GUS MACKER
                        </H3>
                        {tournament.host?.slug
                            ? (
                                <H2 font="h1" color="tertiary400" mb="0">
                                    <Link href={`/local/${tournament.host?.slug}`}>
                                        {tournament.title.toUpperCase()}
                                    </Link>
                                </H2>
                            )
                            : (
                                <H2 font="h1" color="tertiary400" mb="0">
                                    {tournament.title.toUpperCase()}
                                </H2>
                            )
                        }
                        <P font="leader" color="white" mb='0"'>
                            {dayjs(new Date(tournament.start)).format('MMMM Do')} - {dayjs(new Date(tournament.end)).format('Do')}
                        </P>
                    </div>
                </Grid>
            </Box>
            <Box className="hide-on-mobile" mb="md">
                <img
                    alt={tournament.title}
                    src={tournament.image.sizes.thumbnail.url}
                    style={{ width: '100%', aspectRatio: '4/3'}}
                />
            </Box>
            {tournament.host?.slug && (
                <Box ps="md" pe="md">
                    <Link href={`/local/${tournament.host?.slug}`}>
                        <Btn color="tertiary" full outline weight="bold">
                            REGISTER
                        </Btn>
                    </Link>
                </Box>
            )}
        </Box>
    );
};

const WorldTourMap: React.FC<Props> = ({ tournaments }) => {
    const [activeTab, setActiveTab] = useState('all');
    const tournamentList = useMemo(() => tournaments.reduce((reducer, tournament) => {
        const date = `${dayjs(new Date(tournament.start)).format('MMMM D')}-${dayjs(new Date(tournament.end)).format('Do') }`;
        if (activeTab === 'all' || tournament.location.state === activeTab) {
            if (!reducer[date]) {
                reducer[date] = [];
            }
            reducer[date].push(tournament);
        }
        return reducer;
    }, {}), [tournaments, activeTab]);

    const [hoverTournament, setHoverTournament] = useState(null);
    const [activeTournament, setActiveTournament] = useState(null);

    const [showTooltip, setShowTooltip] = useState(false);

    const containerRef = useRef<HTMLDivElement>();

    const tabs = useMemo(() => {
        const states = {};
        return tournaments.reduce((reducer, tournament) => {
            if (!states[tournament.location.state]) {
                states[tournament.location.state] = true;
                reducer.push({
                    label: tournament.location.state,
                    id: tournament.location.state,
                });
            }
            return reducer;
        }, []);
    }, [tournaments]);


    const scrollTo = () => {
        if (containerRef.current) {
            const { left, top } = containerRef.current.getBoundingClientRect();
            window.scrollTo(left, top + window.scrollY);
        }
    }

    return (
        <div
            ref={containerRef}
            style={{
                position: 'relative',
            }}
        >
            <Grid
                columns={{ phone: '1fr', laptop: '1fr 3fr'}}
                rows={{ phone: 'min-content 1fr', laptop: 'auto' }}
                minHeight="100vh" style={{ position: 'static' }}
            >
                <Box
                    bgColor="primary900"
                    bgImage="/images/b-ball-texture.svg"
                    depth="lg"
                    style={{ backgroundAttachment: 'fixed' }}
                    order={{ phone: 2, laptop: 0 }}
                >
                    {activeTournament && (
                        <TournamentCard tournament={activeTournament} />
                    )}
                    {!activeTournament && (
                        <>
                            <Box padding="md" bgColor="rgba(0, 0, 0, 0.5)" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                <H3
                                    color="white"
                                    pt="md"
                                    mb="0"
                                    font={{ phone: 'leader', laptop: 'h3' }}
                                    lineHeight="1"
                                >
                                    GUS MACKER
                                </H3>
                                <H2 font="large" color="tertiary400" mb="md">
                                    {(!activeTab || activeTab === 'all') && 'WORLD TOUR'}
                                    {activeTab && activeTab !== 'all' && stateKeys[activeTab].toUpperCase()}
                                </H2>
                            </Box>
                            <Box padding="md">
                                {Object.keys(tournamentList).map(date => (
                                    <Box mb="sm" key={date}>
                                        <H3 color="tertiary200" font="leader" uppercase weight="normal">{date}</H3>
                                        {tournamentList[date].map(tournament => tournament.host?.slug
                                            ? (
                                                <Link href={`/local/${tournament.host.slug}`} key={tournament.id}>
                                                    <P color="white" mb="xs" uppercase>{tournament.title}</P>
                                                </Link>
                                            )
                                            : (<P key={tournament.id} color="white" mb="xs" uppercase>{tournament.title}</P>)
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        </>
                    )}
                </Box>
                <Box
                    height={{ phone: '50vh', laptop: '100vh' }}
                    style={{
                        display: 'flex',
                        alignItems: 'stretch',
                        position: 'sticky',
                        top: 0,
                        zIndex: 10,
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            position: 'sticky',
                            top: 0,
                            display: 'flex',
                            alignItems: 'stretch',
                            flexGrow: 1,
                        }}>
                            <Map
                                tournaments={tournaments}
                                activeTournament={activeTournament}
                                setActiveTournament={setActiveTournament}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                setHoverTournament={setHoverTournament}
                                setShowTooltip={setShowTooltip}
                                scrollTo={scrollTo}
                            />
                        <div
                            style={{
                                position: 'absolute',
                                left: '50%',
                                top: 0,
                                zIndex: 1,
                                transform: 'translate(-50%, 0)',
                                width: 'max-content',
                                maxWidth: '100%',
                            }}>
                            <Box
                                width="100%"
                                maxWidth="max-content"
                                padding="xs"
                                bgColor="tertiary"
                                depth="lg"
                                rounded="md"
                                margin={['sm', 'auto']}
                                style={{
                                    zIndex: 10,
                                }}
                            >
                                <Tabs
                                    placeholder={activeTournament?.title.toUpperCase() || ''}
                                    label="Select a State"
                                    selected={activeTab}
                                    onChange={setActiveTab}
                                    color="primary700"
                                    txtColor="primary700"
                                    mb="0"
                                    tabs={[{
                                            label: 'WORLD TOUR',
                                            id: 'all',
                                        },
                                        ...tabs
                                    ]}
                                />
                            </Box>
                        </div>
                    </div>
                </Box>
            </Grid>
            <ToolTip show={showTooltip}>
                <Box
                    width="max-content"
                    depth="lg"
                    style={{
                        transform: 'translate(-50%, calc(-100% - 32px))',
                    }}
                >   
                    <TournamentLabel tournament={hoverTournament} />
                </Box>
            </ToolTip>
        </div>
    );
};

export default WorldTourMap;