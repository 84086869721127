import React, { useState, useMemo } from 'react';
import { useRouter } from 'next/router'
import dayjs from 'dayjs';
import {
    Btn,
    Grid,
    Container,
    Box,
    H1,
    H2,
    H3,
    PanelKeys,
} from '@saladbob/sassafras'

import Tabs from '../../navigation/Tabs';
import Image from '../../media/Image';
import WorldTourMap from './WorldTourMap';

import { Upload, Tournament, Host } from '../../../types';

type PropsLabel = {
    image: Upload,
    title: string,
    start: string,
    end: string,
    slug: string,
    host: Host,
}

type Props = {
    title?: string,
    pdf?: Upload,
    tournaments: Tournament[],
    map?: boolean,
}

const TournamentTag: React.FC<PropsLabel> = ({ image, title, start, end, host }) => {
    const router = useRouter();

    const handleClick = () => {
        router.push(`/local/${host.slug}`);
    }

    return (
        <Grid
            txtColor="white"
            bgColor="secondary700"
            columns="min-content 1fr min-content"
            rows={{ phone: 'auto', laptop: '120px' }}
            columnGap="md"
            role="button"
            onClick={handleClick}
            depth="lg"
            bdWidth={[0, 0, 0, 'lg']}
            bdColor="tertiary"
            bgImage="/images/b-ball-texture-r.svg"
            style={{
                backgroundAttachment: 'fixed',
            }}
        >
            <Box>
                {image && (
                    <Image
                        image={image}
                        size="thumbnail"
                        alt={title}
                        width={{ phone: '80px', laptop: '120px' }}
                        aspectRatio='1'
                    />
                )}
            </Box>
            <Box verticalAlign="center" textAlign="left" padding="sm">
                <H2 uppercase font={{ phone: 'p', laptop: 'h2' }} nowrap>{title}</H2>
                <H3 uppercase font={{ phone: 'p', laptop: 'h3' }} weight="normal" color="tertiary">
                    {dayjs(start).format('MMMM DD')}-{dayjs(end).format('DD')}
                </H3>
            </Box>
        </Grid>
    )
}

const WorldTour:React.FC<Props> = ({ title, pdf, tournaments, map }) => {
    if (map) {
        return (<WorldTourMap tournaments={tournaments} />)
    }
    const tournamentByMonth = useMemo(() => tournaments.reduce((acc, tournament) => {
        const month = dayjs(tournament.start).format('MMMM');
        if (!acc[month]) {
            acc[month] = [];
        }

        acc[month].push(tournament);

        return acc;
    }, {}), [tournaments]);

    const osTournaments = useMemo(() => [...tournaments].sort((a, b) => a.location.state >= b.location.state ? 1 : -1), [tournaments]);

    const tournamentByState = useMemo(() => osTournaments.reduce((acc, tournament) => {
        const state = tournament.location.state;
        if (!acc[state]) {
            acc[state] = [];
        }

        acc[state].push(tournament);

        return acc;
    }, {}), [tournaments]);

    const [step, setStep] = useState('all');

    return (
        <Box bgColor="secondary" padding={['xxl', 'md']}>
            <Container size="xl">
                <Box textAlign="center" txtColor="white" mb="xl">
                    <H1
                        font={{ phone: 'h2', laptop: 'jumbo' }}
                        align="center"
                        color="white"
                        mb="lg"
                        uppercase
                    >
                        {title}
                    </H1>
                    <Box
                        margin={{ phone: [0, 'auto', 'md', 'auto'], laptop: [0, 'auto', 'lg', 'auto']}}
                    >
                        <Tabs
                            tabs={[
                                { id: 'all', label: 'All' },
                                ...Object.keys(tournamentByState).map((state) => ({ id: state, label: state })),
                            ]}
                            label="Select a State"
                            color="tertiary"
                            txtColor="tertiary"
                            onChange={setStep}
                            selected={step}
                            align="center"
                        />
                    </Box>
                    <PanelKeys step={step} travel={24}>
                        <Box key="all">
                            { tournaments.length && Object.keys(tournamentByMonth).map((month) => (
                                <Box key={month}>
                                    <H2
                                        font={{ phone: 'leader', laptop: 'h2' }}
                                        uppercase
                                        weight="lighter"
                                        color="tertiary"
                                        mb={{ phone: '0', laptop: 'md' }}
                                    >
                                        {month}
                                    </H2>
                                    <Box
                                        align="stretch"
                                        justify="center"
                                        mb={{ phone: 'sm', laptop: 'lg' }}
                                        wrap
                                    >
                                        {tournamentByMonth[month].map((tournament) => (
                                            <Box
                                                key={`month-${tournament.slug}`}
                                                padding={{ phone: ['sm', 0], laptop: 'md' }}
                                                width={{ phone: '100%', laptop: '50%' }}
                                                minWidth="min-content"
                                                maxWidth="960px"
                                                style={{ flexGrow: 1 }}
                                            >
                                                <TournamentTag key={`month-${tournament.slug}`} {...tournament} />
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        { tournaments.length && Object.keys(tournamentByState).map((state) => (
                            <Box key={state}>
                                <Box
                                    marginBottom="lg"
                                    align="stretch"
                                    justify="center"
                                    mb="lg"
                                    wrap
                                >
                                    {tournamentByState[state].map((tournament) => (
                                        <Box
                                            padding={{ phone: ['sm', 0], laptop: 'md' }}
                                            width={{ phone: '100%', laptop: '50%' }}
                                            minWidth="min-content"
                                            maxWidth="960px"
                                            style={{ flexGrow: 1 }}
                                        >
                                            <TournamentTag key={tournament.slug} {...tournament} />
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        ))}
                    </PanelKeys>
                </Box>
                {pdf && (
                    <Box textAlign="center" txtColor="white" marginBottom="lg">
                        <Btn tag="a" href={pdf} target="_blank" margin="auto" outline color="tertiary">
                            Download Schedule
                        </Btn>
                    </Box>
                )}
            </Container>
        </Box>
    )
}

export default WorldTour;
