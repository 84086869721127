import React, { useRef, useEffect, use} from "react";
import { motion, AnimatePresence } from "framer-motion";

type Props = {
    children: React.ReactNode,
    show: boolean,
    zIndex?: number,
}

const ToolTip: React.FC<Props> = ({ show, zIndex, children }) => {
    const ref = useRef(null);
    const position = useRef({x: 0, y: 0});
    const showRef = useRef(show);
  
    useEffect(() => {
        const updateMousePosition = ev => {
            position.current = { x: ev.clientX, y: ev.clientY };
            if (!ref.current || !showRef.current) return;
            ref.current.style.left = ev.clientX + 'px';
            ref.current.style.top = ev.clientY + 'px';
        };

        window.addEventListener('mousemove', updateMousePosition);

        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
        };
    }, []);

    useEffect(() => {
        showRef.current = show;
        if (!ref.current || !showRef.current) return;
        ref.current.style.left = position.current.x + 'px';
        ref.current.style.top = position.current.y + 'px';
    }, [show]);
    
    return (
        <AnimatePresence>
            {show && (
                <motion.div
                    key="tooltip"
                    className="hide-on-mobile"
                    ref={ref}
                    initial={{ opacity: 0, y: -8 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -8 }}
                    transition={{ duration: 0.2 }}
                    style={{
                        position: 'fixed',
                        pointerEvents: 'none',
                        zIndex: zIndex || 100,
                    }}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ToolTip;